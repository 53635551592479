import domEvents from '../../../events/domEvents';
import '../../../../css/elements/_dialog.pcss';
import modalEvents from '../../../events/modalEvents';
import { disableBodyScroll, enableBodyScroll } from '../../../ui/body';
import Modal from '../../../ui/modal';

export default class ModalComponent {
    protected context: ContextInterface;

    protected keepModalInBackground = false;

    private scrollToTop = false;

    protected modalElement: InteractableHTMLDialogElement | undefined = undefined;

    protected modal: Modal | undefined = undefined;

    constructor(context: ContextInterface) {
        this.context = context;
        this.context.bindings.opened = false;
        this.context.bindings.showFooter = false;
        this.context.bindings.formBound = false;
    }

    public onInit(): void {
        if (this.context.bindings.keepModalInBackground) {
            this.keepModalInBackground = true;
        }

        if (this.context.bindings.scrollToTop) {
            this.scrollToTop = true;
        }

        if (this.context.bindings.primaryButtonText || this.context.bindings.secondaryButtonText) {
            this.context.bindings.showFooter = true;
        }

        const openDialogElements: HTMLElement[] = Array.from(
            this.context.element.querySelectorAll('[data-open]')
        );

        openDialogElements.forEach((element) => {
            element.addEventListener('click', (event: Event) => {
                const modalName = element.dataset.open || '';
                this.openDialog(event, modalName);
            });
        });
    }

    public openDialog(event: Event, modalName: string): void {
        if (event && event.currentTarget instanceof HTMLAnchorElement) {
            event.preventDefault();
        }

        if (modalName && modalName !== this.context.bindings.modalId) {
            return;
        }

        this.modalElement = document.getElementById(
            this.context.bindings.modalId
        ) as InteractableHTMLDialogElement;

        if (this.modalElement === null) {
            this.instantiateModalTemplate();
            this.addEventListeners();
        }

        if (!this.modalElement || !this.modalElement.parentElement) {
            return;
        }

        if (!this.modal) {
            this.modal = new Modal(this.modalElement, this.modalElement.parentElement);
        }

        this.modal.open();
        disableBodyScroll();

        if (this.scrollToTop) {
            const content = this.modalElement.querySelector('[data-content]');
            if (content) {
                content.scrollTop = 0;
            }
        }
        this.context.bindings.opened = true;
        this.onModalOpened();
    }

    private submitForm(event: Event): void {
        event.preventDefault();
        const target = event.target as HTMLElement;
        const formId = target.getAttribute('form');

        if (!formId) {
            return;
        }

        const form = document.getElementById(formId);

        if (form instanceof HTMLFormElement) {
            if (this.context.bindings.formBound === false) {
                form.addEventListener('js-submit', (e) => {
                    e.preventDefault();

                    if (!this.modal) {
                        return;
                    }

                    this.modal.submitForm(form);
                });
                this.context.bindings.formBound = true;
            }

            const submitEvent = new CustomEvent('js-submit');
            form.dispatchEvent(submitEvent);
        }
    }

    public closeDialog(): void {
        if (!this.modal) {
            return;
        }

        this.modal.close();
        enableBodyScroll();
        this.context.bindings.opened = false;
    }

    private addEventListeners(): void {
        if (!this.modalElement) {
            return;
        }

        this.modalElement.addEventListener(modalEvents.closed, () => {
            enableBodyScroll();
            this.onModalClosed();

            if (!this.keepModalInBackground) {
                this.destroy();
            }
        });

        this.modalElement.addEventListener(modalEvents.close, () => {
            this.closeDialog();
        });

        if (this.context.bindings.disableJsFormSubmit) {
            return;
        }

        const form: HTMLFormElement | null = this.modalElement.querySelector('form');
        const submitButton: HTMLButtonElement | null =
            this.modalElement.querySelector('button[type="submit"]');

        if (submitButton && form && !this.#isFormValid(form)) {
            submitButton.disabled = true;
        }

        if (form && !form.dataset.formBound) {
            if (this.context.bindings.endpoint) {
                form.action = this.context.bindings.endpoint;
            }

            form.addEventListener('js-submit', () => {
                if (!this.modal || !submitButton) {
                    return;
                }

                this.modal.submitForm(
                    form,
                    () => {
                        const success = this.getSuccessCallback(this.context.bindings.success);

                        if (typeof success === 'function') {
                            success.apply(this);
                        }
                        this.submitButtonIconToggle(submitButton);
                    },
                    () => {
                        this.submitButtonIconToggle(submitButton);
                    },
                    this.context.bindings.method
                );
            });

            const formFields = [...form.querySelectorAll('input, select')];
            formFields.forEach((field) => {
                field.addEventListener('change', () => {
                    if (this.#isFormValid(form) && submitButton) {
                        submitButton.disabled = false;
                    }
                });
            });

            if (submitButton) {
                submitButton.addEventListener('click', (e) => {
                    if (submitButton.hasAttribute('form')) {
                        return;
                    }

                    e.preventDefault();
                    const event = new CustomEvent('js-submit');
                    form.dispatchEvent(event);

                    this.submitButtonIconToggle(submitButton);
                });
            }
        }

        if (submitButton) {
            submitButton.addEventListener('click', (e) => {
                if (submitButton.hasAttribute('form')) {
                    return;
                }

                e.preventDefault();
                this.onSubmitButtonClick();
            });
        }
    }

    protected getSuccessCallback(callback: string): GenericCallback | null {
        const allowedCallbacks: StandardObjectInterface = {
            closeDialog: this.closeDialog,
            refreshPage: this.refreshPage,
            onSuccess: this.onSuccess,
            onFailure: this.onFailure
        };

        if (allowedCallbacks[callback]) {
            return allowedCallbacks[callback];
        }

        return null;
    }

    protected submitButtonIconToggle(submitButton: HTMLButtonElement): void {
        const buttonIcons = [...submitButton.querySelectorAll('.nh-icon')];
        buttonIcons.forEach((icon) => {
            icon.classList.toggle('hidden');
        });
    }

    private instantiateModalTemplate(): void {
        let modalTemplate: HTMLTemplateElement | null =
            this.context.element.querySelector('.modal-template');

        if (this.context.bindings.templateId) {
            modalTemplate = document.getElementById(
                this.context.bindings.templateId
            ) as HTMLTemplateElement;
        }

        if (!modalTemplate || !modalTemplate?.parentElement) {
            return;
        }

        const clone = modalTemplate.content.cloneNode(true);
        modalTemplate.parentElement.appendChild(clone);

        // Changed this from first dialog, to last dialog for ticket: https://natuurhuisje.atlassian.net/browse/LUM-4568
        // eslint-disable-next-line max-len
        // Better solution would be to replace all instances of this deprecated component in ticket: https://natuurhuisje.atlassian.net/browse/LUM-4079
        const dialogs: InteractableHTMLDialogElement[] = Array.from(
            modalTemplate.parentElement.querySelectorAll('dialog')
        );

        this.modalElement = dialogs[dialogs.length - 1];

        if (this.context.bindings.modalId) {
            this.modalElement = document.getElementById(
                this.context.bindings.modalId
            ) as InteractableHTMLDialogElement;
        }

        if (modalTemplate) {
            this.onTemplateInstantiation(modalTemplate);
        }
    }

    #isFormValid(form: HTMLFormElement): boolean {
        const inputs: Array<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> =
            Array.from(form.querySelectorAll('input, select, textarea'));

        return (
            inputs.filter(
                (element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) =>
                    !element.validity.valid
            ).length === 0
        );
    }

    private refreshPage(): void {
        window.location.reload();
    }

    protected onTemplateInstantiation(modalTemplate: HTMLTemplateElement): void {
        const selector = this.context.bindings.modalId
            ? `#${this.context.bindings.modalId}`
            : '.nh-modal';
        const appendedModal = modalTemplate?.parentElement?.querySelector(selector);

        if (appendedModal) {
            const modalInstantiated = new CustomEvent(domEvents.templateInstantiated, {
                detail: appendedModal
            });
            document.dispatchEvent(modalInstantiated);
        }
    }

    protected onModalOpened(): void {
        // This method is needed to allow child classes to act on this event.
        if (this.modal) {
            this.context.element.dispatchEvent(
                new CustomEvent(modalEvents.opened, { detail: this.modal })
            );
        }
    }

    protected onModalClosed(): void {
        // This method is needed to allow child classes to act on this event.
    }

    protected onSubmitButtonClick(): void {
        // This method is needed to allow child classes to act on this event.
    }

    protected onSuccess(): void {
        // This method is needed to allow child classes to act on this event.
    }

    protected onFailure(): void {
        // This method is needed to allow child classes to act on this event.
    }

    protected destroy(): void {
        this.context.bindings.formBound = false;

        // When the modal is closed by key 27 (esc) the modalElement is undefined
        if (this.modalElement && this.modalElement.parentElement) {
            this.modalElement.parentElement.removeChild(this.modalElement);
        }

        delete this.modalElement;
        delete this.modal;
    }

    static getClassName(): string {
        return 'ModalComponent';
    }
}
